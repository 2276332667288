.block__two {
   min-width: 65%;
   height: 300px;
   padding: 52px 0px 49px 31px;
   background: #FFFFFF;
   box-shadow: 2px 5px 25px -3px rgba(180, 180, 180, 0.25);
   border-radius: 20px;

   .block__two__inner {
      display: flex;
      justify-content: flex-start;
      align-items: center;


      p {
         min-width: 22%;
         font-family: 'Montserrat';
         font-style: normal;
         font-weight: 400;
         font-size: 16px;
         line-height: 17px;
         text-align: left;
         color: #939CB0;
         z-index: 10;

         &:last-child {
            color: #000000;
         }
      }

      svg {
         margin-right: 15px;
      }
   }

   img {
      position: relative;
      left: 275px;
      right: 0;
      bottom: 250px;
      height: 202px;
      width: 460px;
      background-repeat: no-repeat;
      background-position: top center;
      background-size: cover;
   }
}