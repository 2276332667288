footer {
   .example-grid {
      display: flex !important;
      justify-content: space-between;
      align-items: center;
      margin-top: 40px;
      padding: 20px;
      box-shadow: 2px 5px 25px -3px rgba(180, 180, 180, 0.25);
      border-radius: 20px;
   }

   .block {
      width: 14.28571%;
      height: 200px;
      background-color: rgba(71, 147, 255, 0.2);
      margin-right: 20px;
      box-shadow: 2px 5px 25px -3px rgba(180, 180, 180, 0.25);
      border-radius: 10px;
      text-align: start;
      padding: 11px 0 0 11px;
      text-align: left;
      font-family: 'Montserrat';

      &:last-child {
         margin-right: 0;
      }

      h3 {
         margin-bottom: 6px;
         font-size: 18px;
         font-weight: 500;
         line-height: 22px;
         color: rgba(0, 0, 0, 1);
      }

      p {
         margin-bottom: 8px;
         font-size: 14px;
         font-weight: 400;
         line-height: 17px;
         color: rgba(147, 156, 176, 1);
      }
   }
}