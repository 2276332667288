$spCol: #000;

.spinners {
   background-color: #f9f9f9;
   margin-top: 25px;
}

.col-sm-2 {
   padding: 10px;
   border-radius: 4px;
   height: 125px;
   margin-bottom: 10px;
}

.sp {
   width: 32px;
   height: 32px;
   clear: both;
   margin: 20px auto;
}

/* Spinner Wave */
.sp-wave {
   border-radius: 50%;
   position: relative;
   opacity: 1;
}

.sp-wave:before,
.sp-wave:after {
   content: '';
   border: 1px $spCol solid;
   border-radius: 50%;
   width: 100%;
   height: 100%;
   position: absolute;
   left: 0px;
}

.sp-wave:before {
   transform: scale(1, 1);
   opacity: 1;
   -webkit-animation: spWaveBe 0.6s infinite linear;
   animation: spWaveBe 0.6s infinite linear;
}

.sp-wave:after {
   transform: scale(0, 0);
   opacity: 0;
   -webkit-animation: spWaveAf 0.6s infinite linear;
   animation: spWaveAf 0.6s infinite linear;
}

@-webkit-keyframes spWaveAf {
   from {
      -webkit-transform: scale(0.5, 0.5);
      opacity: 0;
   }

   to {
      -webkit-transform: scale(1, 1);
      opacity: 1;
   }
}

@keyframes spWaveAf {
   from {
      transform: scale(0.5, 0.5);
      opacity: 0;
   }

   to {
      transform: scale(1, 1);
      opacity: 1;
   }
}

@-webkit-keyframes spWaveBe {
   from {
      -webkit-transform: scale(1, 1);
      opacity: 1;
   }

   to {
      -webkit-transform: scale(1.5, 1.5);
      opacity: 0;
   }
}

@keyframes spWaveBe {
   from {
      -webkit-transform: scale(1, 1);
      opacity: 1;
   }

   to {
      -webkit-transform: scale(1.5, 1.5);
      opacity: 0;
   }
}